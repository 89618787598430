import React from 'react'
import OngoingProject from '../component/ongoingProject/OngoingProject'
import ProjectBanner from '../component/ongoingProject/ProjectBanner'
import Footer from './Footer'
import Header2 from './Header2'
import { Helmet } from 'react-helmet'

const OurProject = () => {
  return (
    <div>
      <Helmet>
        <title>Ongoing Projects in Gurgaon, Delhi and Noida- Shape My House</title>
        <meta name="description" content="Welcome to Shape My House if you're searching for the top building firm in Delhi, Noida, Gurgaon and Greater Noida- a trustworthy civil construction company." />
      </Helmet>
      <Header2/>
      <ProjectBanner/>
      <OngoingProject/>
      <Footer/>
    </div>
  )
}

export default OurProject
