import React, { useState } from "react";

function BookingForm() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 right-0 bg-[rgba(0,0,0,0.5)] z-50 flex justify-center items-center h-screen">
          <div className="md:w-[40%] rounded-2xl bg-neutral-300 p-6">
            <h2 className="text-2xl md:text-3xl tracking-wide p-2 font-magnolia text-center">
            Book FREE Appointment
            </h2>
            <p className="text-sm md:text-lg px-3 text-center py-5">
            For Best Quality Construction At Affordable Rates
          </p>
        
            <iframe
              title="Contact form"
              className="text-3xl w-full md:text-4xl h-[320px] md:h-[22vw] text-white rounded-2xl font-semibold font-serif"
              id="Contact_Form"
              src="https://account.solidperformers.com/capture_form_data/OTM3"
              border="0"
              style={{ border: 0 }}
            ></iframe>
            <button
              className="block mx-auto mt-4 bg-neutral-300 border-2 text-black border-black hover:border-white hover:text-white rounded-xl px-4 py-2"
              onClick={togglePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center">
        <button
          className="bg-black hover:bg-neutral-600 border-green-900 text-white rounded-3xl font-serif p-2 px-3"
          onClick={togglePopup}
        >
          Talk To Our Expert 
        </button>
      </div>
    </>
  );
}

export default BookingForm;
