import React from "react";
import img1 from "../../assets/photos/blogs/SMH0010.jpg";
import img2 from "../../assets/photos/collaboration_page/CollabrationPic.webp";
import img3 from "../../assets/photos/renovation_page/RenovationPic.webp";
import img4 from "../../assets/photos/InteriorDesigning_Page/InteriorPic.webp";
import img5 from "../../assets/photos/Architectural_Page/ArchitecturalBanner.webp"
import img6 from  "../../assets/photos/vastu_page/VastuPic.webp";
import BookingForm from "./BookingForm";


const ProjectDetails = () => {
  return (
    <div>
      <h3 className="text-[#CCA605] text-lg md:text-2xl xl:text-4xl text-center font-french-canon py-5">
        Professional in 'Home Builder' service ensured
      </h3>

      <h1 className="w-fit text-[#74613C] mx-auto text-sm md:text-2xl font-serif text-center px-3">
        Making Home construction hassle-free, simple, and 100% transparent.
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 md:gap-10 m-3 md:m-10">
        <section
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${img1})` }}
        >
          <div className="w-full h-16 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center rounded-xl p-2 relative group">
            <h2 className="text-white h-20 cursor-pointer pt-3">
              Construction Services
            </h2>
            <p className="text-white hidden text-sm group-hover:block absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3 rounded-b-xl">
              At Shape My House, we pride ourselves on delivering top-notch
              civil contracting services that consistently exceed our clients'
              expectations. We provide rigorous onsite project quality
              management, including technical inspections and testing, to ensure
              every detail meets the highest standards.
            </p>
          </div>
        </section>

        <section
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${img2})` }}
        >
          <div className="w-full h-16 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center rounded-xl p-2 relative group">
            <h2 className="text-white h-20 cursor-pointer pt-3">
              Collaboration Services
            </h2>
            <p className="text-white text-sm  hidden group-hover:block absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3 rounded-b-xl">
              Do you own a plot but don't want to spend your money to build a
              new home? Shape My House has the perfect solution for you! We will
              construct a beautiful, modern home on your plot at our own
              expense. In exchange, we take ownership of one or more floors in
              the newly constructed home. We also provide just construction
              services if you dont want to trade your one floor.
            </p>
          </div>
        </section>

        <section
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${img3})` }}
        >
          <div className="w-full h-16 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center rounded-xl p-2 relative group">
            <h2 className="text-white h-20 cursor-pointer pt-3">
            Renovation Services
            </h2>
            <p className="text-white text-sm  hidden group-hover:block absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3 rounded-b-xl">
            Innovate Home Renovation has been remodeling homes in India for over a
          decade. When planning a home improvement project, it's crucial to
          choose a contractor who is dependable, trustworthy, and qualified. We
          provide comprehensive remodeling services for residential, bathroom,
          interior, and exterior projects, specializing in Noida and Delhi.
            </p>
          </div>
        </section>

        <section
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${img4})` }}
        >
          <div className="w-full h-16 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center rounded-xl p-2 relative group">
            <h2 className="text-white h-20 cursor-pointer pt-3">
            Interior Design Services
            </h2>
            <p className="text-white text-sm  hidden group-hover:block absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3 rounded-b-xl">
            At Shape My House, we understand that your home is your sanctuary,
            and everything about it should be perfect. That's why we are
            dedicated to transforming your home's interior to enhance its beauty
            and functionality. With years of authentic experience, we are among
            the best interior designers in Delhi NCR, committed to bringing your
            vision to life.
            </p>
          </div>
        </section>

        <section
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${img5})` }}
        >
          <div className="w-full h-16 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center rounded-xl p-2 relative group">
            <h2 className="text-white h-20 cursor-pointer pt-3">
            Architectural Design Services
            </h2>
            <p className="text-white text-sm  hidden group-hover:block absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3 rounded-b-xl">
            Our team of talented architects brings years of expertise and a
            passion for creative design to every project. We prioritize your
            needs and vision, ensuring that the final design exceeds your
            expectations. Upholding the highest standards of quality in all
            aspects of our work, we provide comprehensive architectural design
            services from the initial consultation to the project's conclusion.
            </p>
          </div>
        </section>

        <section
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${img6})` }}
        >
          <div className="w-full h-16 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center rounded-xl p-2 relative group">
            <h2 className="text-white h-20 cursor-pointer pt-3">
            Vastu Consultancy Services
            </h2>
            <p className="text-white text-sm  hidden group-hover:block absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 p-3 rounded-b-xl">
            Welcome to Shape My House, where we blend ancient wisdom with modern
          design to create harmonious living spaces. Our Vastu Consultancy
          Services are dedicated to ensuring that your home or office not only
          looks beautiful but also promotes well-being, prosperity, and positive
          energy flow.
            </p>
          </div>
        </section>
      </div>
      <BookingForm/>
    </div>
  );
};

export default ProjectDetails;
