import React from "react";
// import { Link } from "react-router-dom";
import banner from "../../assets/photos/Architectural_Page/Architect-Banner.webp"
// const banner="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719381388/ShapeMyHouse/Banners/ArchitecturalBanner_qyd6hc.jpg"

const ArchitecturalBanner = () => {
  return (
    <div
     className="min-h-[25vh] md:min-h-[35vh] lg:min-h-[30vw] bg-cover bg-center flex flex-col gap-5 justify-center items-center w-full"
      style={{
        backgroundImage:`url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
        {/* <h3 className=" text-lg md:text-3xl z-10 font-french-canon bg-white bg-opacity-70 p-2 rounded-2xl text-center">OUR SERVICES</h3> */}
      {/* <h3 className="text-white bg-black p-3 bg-opacity-30 rounded-2xl text-2xl md:text-6xl z-10 font-french-canon text-center">
        Architectural Design
       
      </h3> */}
    </div>
  );
};

export default ArchitecturalBanner;
