import React from "react";
// import PDFViewer from 'react-pdf-viewer';
import img1 from "../../assets/photos/renovation_page/RenovationPic.webp";
// import img2 from "../../assets/photos/collaboration_page/img2.webp";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";

// import SMH_Specification from "../../../../public/assets/pdf/SMH_Specification.pdf";
// import SMH_PPT from "../../../../public/assets/pdf/SMH_PPT.pdf"
// import Gurgaon_PPT from "../../../../public/assets/pdf/Gurgaon_PPT.pdf"

const RenovationDetails = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };
  // const pdfFile1 = {SMH_Specification};
  // const pdfFile2 = {SMH_PPT};
  // const pdfFile3 = {Gurgaon_PPT};

  return (
    <div className="flex flex-col">
      <section className="flex flex-col gap-5 md:gap-10 w-full p-5 md:p-20 md:pb-10">
        <h2 className="text-2xl md:text-3xl font-french-canon">
          Innovate Home Renovation Services: Transforming Homes in Noida, Delhi,
          and Gurgaon
        </h2>
        <p className="text-base md:text-lg text-black  font-french-canon">
          Innovate Home Renovation has been remodeling homes in India for over a
          decade. When planning a home improvement project, it's crucial to
          choose a contractor who is dependable, trustworthy, and qualified. We
          provide comprehensive remodeling services for residential, bathroom,
          interior, and exterior projects, specializing in Noida and Delhi.
        </p>
        <div className="flex flex-col gap-5">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Our Services:
          </h3>

          <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col">
                <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Masonry
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col">
                <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Carpentry
                </p>
              </div>
            </li>
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col">
                <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Painting and Fall Ceilings
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col">
                <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  General Refurbishment
                </p>
              </div>
            </li>
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col">
                <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Tiling on both floor and wall
                </p>
              </div>
            </li>
          </ol>
        </div>

        <div className="flex flex-col gap-5">
          <h2 className="text-2xl md:text-3xl font-french-canon">
            Planning a Renovation
          </h2>

          <p className="text-base md:text-lg text-black  font-french-canon">
            We start with initial space planning, followed by a detailed
            evaluation of all requirements and customer concerns, ensuring your
            vision is our top priority.
          </p>

          <ol className="flex flex-col  gap-1 md:gap-5 w-full  text-lg font-times md:text-2xl md:text-justify">
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-2xl text-[#CCA605] font-french-canon">
                  Interior Renovations:
                </h4>
                <p className="text-xl">
                  We help you select the ideal colors, furniture, and design
                  elements to give your rooms a complete makeover that aligns
                  with your functional needs and aesthetic preferences.
                </p>
              </div>
            </li>
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-2xl text-[#CCA605] font-french-canon">
                  Move-In Ready Plans:
                </h4>
                <p className="text-xl">
                  Before you move into your new home, we'll handle all your
                  interior needs, from modular kitchens and wardrobe designs to
                  wall décor, false ceilings, and flooring. We make the process
                  simple and stress-free.
                </p>
              </div>
            </li>
          </ol>
        </div>

        <div className="flex flex-col gap-5">
          <h2 className="text-2xl md:text-3xl font-french-canon">OUR TEAM</h2>

          <p className="text-base md:text-lg text-black  font-french-canon">
            Unlike other contractors who subcontract work, we have our own team
            of renovation specialists. This ensures consistency, quality, and
            reliability in every project.
          </p>
        </div>

        <div className="flex flex-col xl:flex-row justify-between">
        <div className="w-full xl:w-1/2 flex flex-col gap-5">

           <h3 className="text-2xl md:text-3xl font-french-canon">
          Additional Services:
        </h3>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Maintenance Services
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Commercial Tenant and Office Renovations
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <p className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Residential Remodeling and Repairs
              </p>
            </div>
          </li>
        </ol>

        </div>
        <img className="w-full xl:w-1/3" src={img1} alt="img1" />
        </div>
       
        <div className="flex flex-col gap-5">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            No-Cost Consultation:
          </h3>
          <p className="text-base md:text-lg text-black  font-french-canon">
            After concept design and material selection, we offer a free
            consultation. We'll present a detailed contract with a breakdown of
            all materials and services at a price that fits your budget. For renovation services in Noida, feel free to reach out to us.
          </p>
        </div>
      </section>

      <section className="flex flex-col gap-10 md:gap-14 w-full p-5 md:p-20 md:pt-0">
        {/* <div className="flex gap-5 mx-auto">
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_Specification}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile1)}
          >
            SMH Specificatio
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile2)}
          >
            SMH PPT
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={Gurgaon_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile3)}
          >
            Gurgaon PPT
          </PDFViewer>
        </div> */}

<div className="flex flex-col md:flex-row gap-5 md:gap-10">

  <div className="bg-black flex flex-col justify-center items-center text-white font-glacial md:p-5 w-full xl:w-1/2 ">
          <img className="w-24 md:w-32 p-1 mx-auto m-3" src={logo} alt="logo" />
          <p className="text-base md:text-xl text-center p-3 font-french-canon">
            Consult with Our Expert & Start today
          </p>
          <div className="py-3">
            <a
              class="text-white text-base font-semibold hover:text-yellow-200"
              href="tel:8510850101"
            >
              <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
                {" "}
                8510 850 101
              </p>
            </a>
            <p className="text-lg text-center font-sans tracking-widest">
              Email:{" "}
              <a
                className="text-white text-base md:hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                info@tsgoc.in
              </a>
            </p>
          </div>
          <button
            onClick={handleButtonClick}
            className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e] hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
          >
            Press
          </button>
        </div>
        <div className="flex flex-col gap-3  w-full xl:w-1/2">
          <a
            href="/service/construction"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Construction
          </a>
          <a
            href="/service/collaboration"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Collaboration
          </a>

          <a
            href="/service/interior-design"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Interior Design
          </a>
          <a
            href="/service/architectural-design"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Architectural Design
          </a>
          <a
            href="/service/vastu-consultancy"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Vastu Consultancy
          </a>
        </div>

</div>


      

        {/* <img className="w-full" src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719385967/ShapeMyHouse/Pics/RenovationPic_ox71r8.webp" alt="img2" /> */}
     
      </section>
    </div>
  );
};

export default RenovationDetails;
