import React from "react";
import img from "../../assets/photos/ongoingProjects/ONGPRO_P1.jpg"
import BookingForm from "./BookingForm";
// import bg_project from '../Assets/bg_img/bg_project.jpg';

function About() {
  return (
    <div className="flex flex-col gap-3 md:gap-0 text-[#74613C] p-2 md:p-5 md:py-10">
      <h3 className="text-[#CCA605] text-xl md:text-3xl xl:text-5xl text-center font-french-canon py-5">
        Best Home Construction for Your Dream Home Delhi NCR
      </h3>
      <div className=" flex flex-col md:flex-row shadow-lg md:p-3">
        <div className="flex flex-col gap-5 p-3 md:py-10 md:m-14 md:my-0 bg-white w-full md:w-1/2">
          <h1 className="w-fit text-[#74613C] mx-auto text-sm md:text-2xl font-serif text-center px-3">
            We construct your dream home, Your Project Our Control
          </h1>
          <p className="text-lg md:text-xl text-neutral-400 px-3 text-justify">
            On time project schedules, realistic budgets, apt project timelines
            and customer satisfaction are some of the main key points of Project
            Management which give the homeowner complete control of their
            construction project.
          </p>
          <BookingForm/>
        </div>

        <img className="w-full md:w-1/3 rounded-2xl" src={img} alt="project 1"/>
      </div>
    </div>
  );
}

export default About;
