import React from "react";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";

const ArchitecturalDetails = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };

  return (
    <div className="flex flex-col">
      <section className="flex flex-col gap-5 md:gap-10 w-full p-5 md:p-20 md:pb-10">
        <h2 className="text-2xl md:text-3xl font-french-canon">
          Shape My House: Architectural Design Services in Delhi-NCR
        </h2>
        <p className="text-base md:text-lg text-black  font-french-canon">
          Here at Shape My House, we take your architectural fantasies and make
          them come true. Our specialty is coming up with creative and useful
          architecture solutions that seamlessly combine style and
          functionality. We are dedicated to providing designs that improve your
          quality of life and place of employment, and we serve the whole
          Delhi-NCR area, including Gurgaon, Noida, and beyond.
        </p>

        <h2 className="text-2xl md:text-3xl font-french-canon">
          Our Services for Architectural Design:
        </h2>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h2 className="text-xl md:text-2xl font-french-canon">
            Customized Consultation for Design:
          </h2>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Recognizing Your Vision:
              </h4>
              <p className="text-base md:text-lg">
                We start with a thorough consultation to ascertain your
                particular requirements, inclinations, and way of life.
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl w-[244px] text-[#CCA605] font-french-canon">
                Concept Development:
              </h4>
              <p className="text-base md:text-lg">
                Our architects work with you to translate your ideas into a
                workable concept that satisfies both your functional needs and
                aesthetic preferences.
              </p>
            </div>
          </li>
        </ol>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h4 className="text-xl md:text-2xl font-french-canon">
            Extensive Scheduling and Evaluation:
          </h4>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Site investigation:
              </h4>
              <p className="text-base md:text-lg">
                In order to comprehend the site's possibilities and constraints,
                we perform a comprehensive investigation of it.
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Space Optimization:
              </h4>
              <p className="text-base md:text-lg">
                To ensure optimal efficiency and usefulness, our designs place a
                strong emphasis on space optimization.
              </p>
            </div>
          </li>
        </ol>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h3 className="text-xl md:text-2xl font-french-canon">
            Creative Approaches to Design:
          </h3>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Sustainable Design:
              </h4>
              <p className="text-base md:text-lg">
                By incorporating sustainable techniques into our designs, we
                encourage environmental responsibility and energy efficiency.
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Modern Aesthetics:
              </h4>
              <p className="text-base md:text-lg">
                We keep classic elegance while reflecting current trends in our
                designs.
              </p>
            </div>
          </li>
        </ol>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h3 className="text-xl md:text-2xl font-french-canon">
            Detailed Sketches of the Architecture:
          </h3>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Accuracy and Clarity:
              </h4>
              <p className="text-base md:text-lg">
                We guarantee accuracy and precision by offering comprehensive
                architectural drawings that include sections, elevations, and
                floor plans.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605]">
              3D <span className=" font-semibold font-french-canon"> Visualizations:</span> 
              </h4>
              <p className="text-base md:text-lg">
                Our 3D renderings provide you with a realistic perspective of
                your project and assist you in seeing it through to completion.
              </p>
            </div>
          </li>
        </ol>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h3 className="text-xl md:text-2xl font-french-canon">
            Approvals and Regulatory Compliance:
          </h3>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Navigating Regulations:
              </h4>
              <p className="text-base md:text-lg">
                We take care of the required approvals and guarantee adherence
                to regional building norms and standards.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-base md:text-lg text-[#CCA605] font-french-canon">
                Easy Process:
              </h4>
              <p className="text-base md:text-lg">
                Our staff handles every step of the approval procedure, giving
                you a hassle-free experience.
              </p>
            </div>
          </li>
        </ol>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Management of Projects:
          </h3>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Smooth Coordination:
              </h4>
              <p className="text-base md:text-lg">
                To guarantee a project's smooth execution, we communicate with
                engineers, contractors, and other stakeholders.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col md:flex-row gap-2">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                On-time Delivery:
              </h4>
              <p className="text-base md:text-lg">
                Your project will be finished on schedule and under budget
                thanks to our project management.
              </p>
            </div>
          </li>
        </ol>

        {/* <img src={img1} alt="img1" /> */}
      </section>

      <section className="flex flex-col gap-10 md:gap-14 w-full p-5 md:p-20 md:pt-0">
        {/* <div className="flex gap-5 mx-auto">
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_Specification}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile1)}
          >
            SMH Specificatio
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile2)}
          >
            SMH PPT
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={Gurgaon_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile3)}
          >
            Gurgaon PPT
          </PDFViewer>
        </div> */}
        <div className=" flex flex-col gap-5 md:gap-10">
          {/* <img
          className="w-full xl:w-1/3"
          src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719381604/ShapeMyHouse/Pics/Architecturalpic_f9nfgy.jpg"
          alt="img2"
        /> */}
          <div className="flex flex-col gap-5 xl:text-justify">
            <h3 className="text-2xl md:text-3xl font-french-canon">
              Why Should You Choose us For Architectural Style?
            </h3>
            <p className="text-base md:text-lg text-black  font-french-canon">
              Shape My House, an architect company in Noida offers a team of
              talented architects who bring years of expertise and a passion for
              creative design to every project. We prioritize your needs and
              vision, ensuring that the final design exceeds your expectations.
              Upholding the highest standards of quality in all aspects of our
              work, we provide comprehensive architectural design services from
              the initial consultation to the project's conclusion.
            </p>
          </div>
          <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl ">
            <h3 className="text-2xl md:text-3xl font-french-canon">
              Our Specializations:
            </h3>
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col md:flex-row gap-2">
                <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                  Residential design :
                </h4>
                <p className="text-base md:text-lg">
                  is the process of making stunning, useful homes that express
                  your individuality and way of life.
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col md:flex-row gap-2">
                <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                  Commercial design :
                </h4>
                <p className="text-base md:text-lg">
                  is the process of creating effective and motivating
                  environments for companies, workplaces, and retail stores.
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col md:flex-row gap-2">
                <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                  Remodeling and renovation:
                </h4>
                <p className="text-base md:text-lg">
                  converting old spaces into contemporary, useful, and visually
                  beautiful settings.
                </p>
              </div>
            </li>
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col md:flex-row gap-2">
                <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                  Landscape architecture:
                </h4>
                <p className="text-base md:text-lg">
                  Adding tasteful and imaginative landscape designs to outdoor
                  areas.
                </p>
              </div>
            </li>
          </ol>
          <p className="text-base md:text-lg text-black  font-french-canon">
            Start Using Shape My House: Are you prepared to realize your
            architectural dream? For a free consultation and assistance in
            designing a home that is exclusively yours, get in touch with Shape
            My House right now.
          </p>
        </div>

        <div className=" flex flex-col md:flex-row gap-5 md:gap-10">
          <div className="bg-black w-full md:w-1/2 flex flex-col justify-center items-center text-white font-glacial md:p-5">
            <img
              className="w-24 md:w-32 p-1 mx-auto m-3"
              src={logo}
              alt="logo"
            />
            <p className="text-base md:text-xl text-center p-3 font-french-canon">
              Consult with Our Expert & Start today
            </p>
            <div className="py-3">
              <a
                class="text-white text-base font-semibold hover:text-yellow-200"
                href="tel:8510850101"
              >
                <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
                  8510 850 101
                </p>
              </a>
              <p className="text-lg text-center font-sans tracking-widest">
                Email:
                <a
                  className="text-white text-base md:hover:text-blue-400 "
                  href="mailto:info@tsgoc.in"
                >
                  info@tsgoc.in
                </a>
              </p>
            </div>
            <button
              onClick={handleButtonClick}
              className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e] hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
            >
              Press
            </button>
          </div>
          <div className="w-full md:w-1/2 flex flex-col gap-3 md:gap-5 mx-auto">
            <a
              href="/service/construction"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Construction
            </a>
            <a
              href="/service/collaboration"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Collaboration
            </a>
            <a
              href="/service/renovation"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Renovation
            </a>

            <a
              href="/service/interior-design"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Interior Design
            </a>
            <a
              href="/service/vastu-consultancy"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Vastu Consultancy
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArchitecturalDetails;
