import React from "react";
import img from "../../assets/photos/blogs/SMH0013.jpg";
import { Helmet } from "react-helmet";
import { GoDotFill } from "react-icons/go";


const Blog13 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>Introduction of the Best Builder of Delhi - Shape My House</title>
        <meta
          name="description"
          content="Shape My House is not only the best Builders in Delhi but by conquering on many big projects it is now emerging as the best builders in Noida and Gurgaon also."
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2023-02-17 02:13:51
          </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          Introduction of the Best Builder of Delhi - Shape My House
          </h1>
        </div>

        <img className="w-full" src={img} alt=" Best Builder of Delhi" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Shape my house comes under the company named The Shubham Group Of Companies, which marked their success in residential projects and entered other fields such as Collaboration,Construction and Resorts. Actually, Shape My House is one of the top builders of Delhi. It envisions the primary preference nationally and globally for their renowned excellence, quality, performance and reliability in all types of construction.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          The main aim of Shape My House is to work openly and honestly for anyone in need of any architectural design, home or office construction, collaboration, or renovation. Shape my house has defeated others by becoming the best builders in Gurgaon also. They provide interior design services in Delhi-NCR and are a trustworthy building contractor. Shape My House's responsibility is to ascertain the client's needs and to recommend or offer the highest-quality goods and services within a set time frame. Also, it is the preeminent provider of superior construction services by consistently improving the quality of our product so that the client’s integrity, value and foresight can be added.
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          OUR WORKING PROCESS
          </p>
          
          <p className="flex text-md md:text-xl px-3 font-french-canon text-justify ">
            <GoDotFill className="m-1 ml-0"/>Understanding the Need
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Plans are started after taking the needs of the client into account.
          </p>

          <p className="flex text-md md:text-xl px-3 font-french-canon text-justify ">
            <GoDotFill className="m-1 ml-0"/>Idea & Design
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Your thoughts are transformed into designs by our qualified team of architects and designers.
          </p>

          <p className="flex text-md md:text-xl px-3 font-french-canon text-justify ">
            <GoDotFill className="m-1 ml-0"/>Specification
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          The smallest details, such as materials, quantity, and workmanship quality, are attended to.
          </p>

          <p className="flex text-md md:text-xl px-3 font-french-canon text-justify ">
            <GoDotFill className="m-1 ml-0"/>Execution
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Putting all the plans into action to complete the project.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Many builders in Delhi, Gurgaon and Noida are doing services such as construction, renovation and collaboration. Let’s discuss them one by one.
          </p>
         
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          Collaboration
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          There are six crucial criteria that were identified as influencing contractor's willingness to cooperate:
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          1. Collaboration encourages teamwork;
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          2. Similar racial partnership fosters teamwork among members;
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          3. Stimulate information sharing;
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          4. Improves quality and timely project completion;
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          5. Enhance service quality; and
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          6. Better communication among project
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          Benefits of Collaboration:
          </p>
          
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/>Contemporary new construction with all the newest facilities.
          </p>
          
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> Professional team with good technical expertise
          </p>
    
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> At each stage of the process, construction quality is assured.
          </p>
         
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/>24/7 CCTV surveillance at the site
          </p>
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/>Give the owner the chance to sell his or her share at a higher price if necessary.
          </p>
         
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/>10 years of structural guarantee and one year of functional warranty.
          </p>
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/>On-time delivery
          </p>


          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          What we offer in collaboration:
          </p>

         
          <p className="text-md md:text-xl px-3 font-french-canon  text-justify ">
         <span className="font-sans font-semibold">1.</span>  Preliminary
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          We drafted a collaboration agreement that includes material and fixture specifications and lists all the usual terms and conditions.
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">2.</span> Planning
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          The layout plan is designed by an architect and interiors team, and when the landowner reviews the interior and exterior variations, we move through with requesting approval from the relevant authority.
          </p>


          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">3.</span> Construction
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Then we start construction.
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">4.</span>  Possession Handover
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Following the conclusion of construction, the parties received their agreed-upon share of the floors.
          </p>



          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          CONSTRUCTION
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Nine important factors were found that lead to a willingness to construction among contractors is:
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          1. Perform appropriate site due to tirelessness
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          2. Find an engineer who has experience in your state.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          3. Right-size the facility
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          4. Anticipate that building expenses should winding
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          5. Configuration to suit the strengths
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          6. Choose and incorporate gear
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          7. Consider how it will affect routine activities.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          8. Know the traps of existing space
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          9. Client Satisfaction
          </p>


          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          What we offer in Construction:
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          1. At Shape My House, we offer superior civil contracting services that go above and above for our customers. Our responsible site managers approach the building process with devotion, hard effort, and honesty in order to achieve this. They also hire and keep a first-class professional team.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          2. We function systematically by articulating and defining the goals of the project and then translating this early planning into contract documents.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          3. We also give an overview of the testing, technical inspection, and project quality on site.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          4. The final accounting, construction closeout list, and full information on the entire project are also provided.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          5. We focus on durable and efficient structures that withstand the tide of time. implementing best practices for structural contracting, including work on forms, brickwork, and reinforcing.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          6. We guarantee to build your dream home that is based on your needs and mirrors your personality because we are the best civil engineering com
          </p>



          <p className="text-lg md:text-2xl p-3 font-french-canon text-center ">
          RENOVATION
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          Renovation is the process of restoring a structure to its original condition through repairs and improvements, or the actual improvements made. There are synonyms of renovation such as Renovate, renew, restore, refresh, and rejuvenate all mean to make like new. Renovate (a word derived from the Latin verb novare, meaning "to make new," itself a descendant of novus, meaning "new") suggests a renewing by cleansing, repairing, or rebuilding. During renovation projects, flexibility is often required from builders in Noida to respond to unexpected issues that arise. The participants follow a plan that had been agreed upon by all. Investors in the project's funding as well as the designer's input will be solicited during the planning phase.
          </p>
        </div>
      </section>




      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
        <a href="/blog/know-why-home-orientation-is-necessary" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Know Why Home Orientation Is Necessary
        </a>

        <a href="/blog/the-development-and-future-of-the-construction-industry-in-india" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        The Development and future of the Construction Industry in India
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Construction ban reimposed in Delhi NCR as air quality worsens
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
        </a>

        <a href="/blog/key-construction-industry-trends-to-look-out-for-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog13;
