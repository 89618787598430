import React from 'react'
import BannerAdvert from './BannerAdvert'
import About from './About'
import BottomButtons from './BottomButtons'
import ProjectDetails from './ProjectDetails'
import CompanyPolicies from './CompanyPolicies'
import Testimonials from '../../screens/Testimonial'
import img1 from '../../assets/photos/advert.png'

const AdvertHome = () => {
  return (
    <div>
      <BannerAdvert/>
      <About/>
      <ProjectDetails/>
      <CompanyPolicies/>
<img src={img1} alt='pic1'/>
      <Testimonials/>
      <BottomButtons/>
    </div>
  )
}

export default AdvertHome
