import React from "react";
// import { Link } from "react-router-dom";
import banner from "../../assets/photos/InteriorDesigning_Page/Interior-Banner.webp"

// const banner="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719381709/ShapeMyHouse/Banners/InteriorBanner_sl0tqz.jpg"

const InteriorDesignBanner = () => {
  return (
    <div
        className="min-h-[25vh] md:min-h-[35vh] lg:min-h-[30vw] bg-cover bg-center flex flex-col gap-5 justify-center items-center w-full"
      style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
        {/* <h3 className="text-[#eecf9d] text-lg md:text-3xl z-10  font-french-canon text-center bg-black bg-opacity-40 p-3">OUR SERVICES</h3> */}
      {/* <h3 className="text-white text-2xl md:text-6xl z-10  font-french-canon text-center">
      Interior Design
      </h3> */}
    </div>
  );
};

export default InteriorDesignBanner;
