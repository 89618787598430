import React from "react";
import img from "../../assets/photos/blogs/SMH001.jpg";
import img2 from "../../assets/photos/blogs/SMH001.2.jpg";
import { GoDotFill } from "react-icons/go";

import { Helmet } from "react-helmet";

const Blog1 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>The Importance of the Home-Orientation | Shape My House</title>
        <meta
          name="description"
          content="Shape my house is the best builder in Delhi NCR and construction company in Delhi NCR. Our team is by your side to ensure you have a hassle-free experience"
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2022-11-16 10:29:38

         </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          Know why home orientation is necessary
          </h1>
        </div>

        <img className="w-full" src={img} alt="Home-Orientation" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The position or orientation of your home with respect to the sun's path and any prevailing winds is referred to as house orientation. When your house is oriented properly, you may block sunlight in the summer and receive just the correct quantity of solar radiation to warm it up in the winter. This can significantly reduce the amount of time you spend using heating and cooling systems, which will lower your electricity costs. 
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Principles of Good Orientation: 
          </p>

          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> The Indian construction industry’s growth
          </p>
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> Critical insight into the impact of industry trends and issues
          </p>
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> Analysis of the mega-project pipeline
          </p>
          
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          The report’s key highlights are - 
          </p>
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        The most crucial elements to take into account for optimal orientation are
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          <span className="font-semibold font-sans">1.</span> The climate in the area
          </p>
          

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">2.</span> Your site or building's real north and sun angles
          </p>
         

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">3.</span> selecting the best construction style for your climate zone
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">4.</span> The results of global warming
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Ideally, you should choose the best construction company for home with good orientation for the climate in your region or just google, the best house renovation near me and build or renovate to maximize the site’s potential for passive heating and passive cooling.
          </p>
        
        <img className="w-full" src={img2} alt="construction company in Delhi NCR" />

        <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
        Benefits of Home Orientation:    
          </p>
        

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          <span className="font-semibold font-sans">1.</span> You are shielded from the sun's harmful rays by the open area to the west.
          </p>
          

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">2.</span> In warmer climates, cross ventilation improves indoor comfort; in cold climates, it decreases it.
          </p>
         

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">3.</span> The building must be oriented east-west rather than north-south in order to reduce glare from the sun's rising and setting rays. This is necessary for visual comfort.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          If you’re clueless and not getting structure properly just check house builders near me, who will help you with the best. 
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Orientation For Location: :    
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The location of your home is a crucial consideration when constructing the ideal ventilation system since it enables you to compare the wind flow produced by efficient and inefficient ventilation systems. There’s a plus point if you’re living in Delhi, you can get a quotation with the best builder in Delhi NCR easily at your convenience. 
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Rainfall:      
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The sunshades will help to protect your inside area because rainfall might be at its heaviest during cyclones. In order to control the interior temperature at night, heat is preserved by sunshades. Thus, home orientation enables you to take full use of natural light and ventilation in all climatic situations and these all ways can be implemented by any experienced construction company in Delhi simply.
          </p>

        </div>
      </section>

      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
      <a href="/blog/the-development-and-future-of-the-construction-industry-in-india" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        The Development and future of the Construction Industry in India
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Construction ban reimposed in Delhi NCR as air quality worsens
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
        </a>

        <a href="/blog/key-construction-industry-trends-to-look-out-for-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Introduction of the Best Builder of Delhi - Shape My House
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog1;
