import React from 'react';
import { Link } from 'react-router-dom';
import banner from "../../assets/photos/contact_page/banner.webp";

const ContactBanner = () => {
  return (
    <div
      className="min-h-80 md:min-h-[35vw] flex justify-center items-center w-full"
      style={{ backgroundImage: `url(${banner})` }}
    ><h3 className='text-white text-3xl md:text-6xl z-10 font-french-canon text-center'>Contact Us
     {/* <Link to="/">
          <span className=" text-black text-lg md:text-2xl font-glacial bg-[#f6c87f] hover:text-[#f6c87f] md:px-2 p-1 hover:bg-white ">HOME</span>
          </Link>  */}
    </h3>      
    </div>
  );
};

export default ContactBanner;
