import React from "react";

const ContactDetails = () => {
  return (
    <div
      className="flex flex-col md:flex-row gap-10 p-5 xl:p-20 pt-10"
      data-scroll
      data-scroll-section
      data-scroll-speed="0"
    >
      <section className="flex flex-col gap-2 md:gap-5 w-full md:w-1/2 md:p-4 xl:p-10 rounded-xl bg-black hover:bg-slate-900 hover:scale-95 duration-500">
        <h2 className="text-[#EABB26] text-2xl md:text-3xl font-french-canon pt-5 p-3">
          Contact Us
        </h2>
        <p className="text-sm md:text-lg  font-glacial px-3 text-pretty text-justify text-white">
          We would love to respond to your queries. Feel free to get in touch
          with us.
        </p>

        <iframe
          className="text-3xl md:text-4xl w-full text-white rounded-2xl md:py-3 font-semibold font-serif"
          title="CRM-Form"
          id="Contact_Form"
          src="https://account.solidperformers.com/capture_form_data/MTAwNw=="
          // src="https://account.solidperformers.com/capture_form_data/MTAxMQ=="
          border="0"
          style={{ border: 0, height: "300px" }}
        ></iframe>
      </section>

      <section className="flex flex-col gap-2 md:gap-5 w-full md:w-1/2 md:p-4 xl:p-10 rounded-xl bg-black hover:bg-slate-900 md:hover:scale-95 duration-500">
        <h2 className="text-[#EABB26] text-2xl md:text-3xl font-french-canon pt-5 p-3">
          Reach US
        </h2>
        <p className="text-sm md:text-lg  font-glacial text-pretty px-3 text-justify text-white">
          We are here to help you build your Dream Home!
        </p>

        <iframe
          title="CRM form"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.7493073771757!2d77.2411246149465!3d28.547253994686578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3eb586df089%3A0x4e0d4c7bf0a27c89!2sCollaboration%20Builders%20in%20Delhi-Gurgaon%20-%20Shape%20My%20House!5e0!3m2!1sen!2sin!4v1632466735455!5m2!1sen!2sin"
          className="w-full h-80 border-0 p-3 xl:p-4"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>
    </div>
  );
};

export default ContactDetails;
