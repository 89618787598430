import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import logo from "../assets/logos/SMH-Logo.png";
import whatsapp from "../assets/logos/whatsapp3.png";
import call from "../assets/photos/ongoingProjects/customer-support.png";
import BookingForm from "./BookingForm";

const products = [
  {
    name: "Construction",
    description: " Provide optimum quality civil contracting services",
    href: "/service/construction",
    icon: ChartPieIcon,
  },
  {
    name: "Collaboration",
    description: "Speak directly to your customers",
    href: "/service/collaboration",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Renovation",
    description: "Your customers’ data will be safe and secure",
    href: "/service/renovation",
    icon: FingerPrintIcon,
  },
  {
    name: "Interior Design ",
    description: "Connect with third-party tools",
    href: "/service/interior-design",
    icon: ArrowPathIcon,
  },
  {
    name: "Architectural Design ",
    description: "Build strategic funnels that will convert",
    href: "/service/architectural-design",
    icon: ArrowPathIcon,
  },
  {
    name: "Vastu Consultancy",
    description: "Build strategic funnels that will convert",
    href: "/service/vastu-consultancy",
    icon: SquaresPlusIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  {
    name: "Contact sales",
    href: "https://wa.me/919870168989",
    target: "_blank",
    rel: "noopener noreferrer",
    icon: PhoneIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-[#D6CFBF]">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-3 xl:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 px-1.5 md:p-0 ">
            <span className="sr-only">Your Company</span>
            <img
              className="h-16 md:h-20 xl:h-24 w-auto"
              src={logo}
              alt="Company Logo"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 md:h-10 md:w-10" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <a
            href="/"
            className="text-sm md:text-lg font-semibold leading-6 pt-1 hover:scale-125 duration-500 text-[#937B4D] hover:text-[#CCA605]"
          >
            Home
          </a>
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 pt-1 text-sm md:text-lg font-semibold leading-6 hover:scale-125 duration-500 text-[#937B4D] hover:text-[#CCA605]">
              Services
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </PopoverButton>

            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute -left-8 top-full mt-3 z-40 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm  leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="block font-semibold text-[#937B4D]"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-[#937B4D] hover:bg-gray-100"
                    >
                      <item.icon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>

          <a
            href="/our-projects"
            className="text-sm md:text-lg font-semibold leading-6 pt-1 hover:scale-125 duration-500 text-[#937B4D] hover:text-[#CCA605]"
          >
            Featured Projects
          </a>
          {/* <a
            href="#"
            className="text-sm md:text-lg font-semibold leading-6 pt-1 hover:scale-125 duration-500 text-[#937B4D] hover:text-[#CCA605]"
          >
            Gallery
          </a> */}
          <a
            href="/contact_us"
            className="text-sm md:text-lg font-semibold leading-6 pt-1 hover:scale-125 duration-500 text-[#937B4D] hover:text-[#CCA605]"
          >
            Reach Us
          </a>
          <a
            class="flex gap-2 justify-center items-center text-sm font-semibold leading-6 hover:scale-125 duration-500 text-white bg-black bg-opacity-30 rounded-xl py-1 px-2"
            href="tel:8510850101"
          >
            <img className="w-6" src={call} alt="logo" />
            Call Us 9870 168 989
          </a>
        </PopoverGroup>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div> */}
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 -ml-3 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-16 w-auto" src={logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon
                className="h-6 w-6 md:h-10 md:w-10"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="mt-6 flow-root ">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="/"
                  className="text-sm md:text-2xl font-semibold leading-6 text-gray-900"
                >
                  Home
                </a>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base md:text-2xl font-semibold text-gray-900 hover:bg-gray-50">
                        Services
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block py-2 pl-6 pr-3 text-sm md:text-2xl font-semibold text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="/our-projects"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base md:text-2xl font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Featured Projects
                </a>
                {/* <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base md:text-2xl font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Gallery
                </a> */}
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base md:text-2xl font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Reach Us
                </a>
              </div>
              {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>

      <BookingForm />
      {/* WhatsApp Button */}
      <div
        // className="left-[20px] md:left-[92vw]"
        style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 30 }}
      >
        <a
          href="https://wa.me/919870168989"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={whatsapp}
            alt="WhatsApp"
            style={{
              width: "50px",
              height: "50px",
              // borderRadius: "40%",
              // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          />
        </a>
      </div>
    </header>
  );
}
