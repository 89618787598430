import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import blog1 from "../../assets/photos/ongoingProjects/ONGPRO_P1.jpg";
import blog2 from "../../assets/photos/ongoingProjects/ONGPRO1.jpg";
// import blog3 from "../../assets/photos/ongoingProjects/ONGPRO6.jpg";

const OngoingProject = () => {
  return (
    <div className="bg-[#f0d6aa]">
      <Helmet>
        <title>
          Ongoing Projects in Gurgaon, Delhi and Noida- Shape My House
        </title>
        <meta
          name="description"
          content="Welcome to Shape My House if you're searching for the top building firm in Delhi, Noida, Gurgaon and Greater Noida- a trustworthy civil construction company."
        />
      </Helmet>
      <div className="flex flex-col items-center justify-center py-5 md:py-10">
        <p className="text-lg md:text-xl font-french-canon text-[#F19F1A]">
          OUR WORKS
        </p>
        <h3 className="p-3 text-2xl text-start md:text-4xl font-french-canon">
          ONGOING PROJECTS
        </h3>
        <h1 className="p-3 text-xl text-start md:text-2xl font-french-canon">
          We offer building construction services as per your Specification with
          in the suitable time and budget.
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 py-5 md:pb-16 md:m-auto">
        {/* .......1....... */}
        <div
          className="w-full h-56 md:h-80 xl:h-96 md:w-96 bg-cover bg-center m-auto rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog1})` }}
        >
          <Link
            to="/ongpro2"
            // target="_blank"
            className="w-full text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <div className=" md:h-28 text-white hover:text-black">
                 <p>
              Noida, Sector- <span className="font-sans">105, 201301</span>
            </p>
            <p className="font-sans font-thin">Plot Size: 200 sq. yards</p>
            <p className="font-sans font-thin">Home Type: New construction with the duplex concept</p>

            </div>
           
          </Link>
        </div>

        {/* .......2....... */}
        <div
          className="w-full h-56 md:h-80 xl:h-96 md:w-96 bg-cover bg-center rounded-2xl m-auto flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog2})` }}
        >
          <Link
            to="/4-bhk-apartment-in-dlf-phase-3-gurgaon"
            // target="_blank"
            className="w-full text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
             <div className=" md:h-20 md:pt-4 text-white hover:text-black">
                 <p>
                 Gurugram, DLF Phase<span className="font-sans">-3 122010</span>
            </p>
            
            <p className="font-sans font-thin">Home Type: 4 BHK Apartment</p>

            </div>
          </Link>
        </div>

        {/* .......3....... */}
        {/* <div
          className="w-full h-56 md:h-80 md:w-96 bg-cover bg-center m-auto rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog3})` }}
        >
          <Link
            to="/"
            target="_blank"
            className="w-full text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
               <div className=" md:h-20 md:pt-4 text-white hover:text-black">
                 <p>
                 C.R. Park
            </p>
            <p className="font-sans font-thin">Home Type: 4 BHK Apartment</p>
            </div>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default OngoingProject;
