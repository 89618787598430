import React from "react";
import RenovationDetails from "../component/servicepage/RenovationDetails";
import RenovationBanner from "../component/servicepage/RenovationBanner";
import Header2 from "./Header2";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Renovation = () => {
  return (
    <div>
      <Helmet>
        <title>
        Renovation Company In Noida | Best Renovation Services
        </title>
        <meta
          name="description"
          content="Unlock exceptional renovation services in Noida with our dedicated renovation company in Delhi NCR."
        />
      </Helmet>
      <Header2 />
      <RenovationBanner />
      <RenovationDetails />
      <Footer />
    </div>
  );
};

export default Renovation;
