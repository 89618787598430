import React from "react";
// import PDFViewer from 'react-pdf-viewer';
import img1 from "../../assets/photos/construction_page/ConstructionPic.webp";
// import img2 from "../../assets/photos/construction_page/img2.webp";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";

// import SMH_Specification from "../../../../public/assets/pdf/SMH_Specification.pdf";
// import SMH_PPT from "../../../../public/assets/pdf/SMH_PPT.pdf"
// import Gurgaon_PPT from "../../../../public/assets/pdf/Gurgaon_PPT.pdf"

const ConstructionDetails = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };
  // const pdfFile1 = {SMH_Specification};
  // const pdfFile2 = {SMH_PPT};
  // const pdfFile3 = {Gurgaon_PPT};

  return (
    <div className="flex flex-col ">
      <section className="flex flex-col gap-5 md:gap-10 w-full p-5 md:p-20">
        <h2 className="text-2xl md:text-3xl font-french-canon">
          Shape My House: Construction Services in Delhi-NCR
        </h2>
        <p className="text-base md:text-lg text-black  font-french-canon">
          At Shape My House, we pride ourselves on delivering top-notch civil
          contracting services that consistently exceed our clients
          expectations. Here's what sets us apart:
        </p>
        <h3 className="text-2xl md:text-3xl   font-french-canon">
          Exceptional Quality and Expertise:
        </h3>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full  text-lg font-times md:text-2xl md:text-justify">
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Experienced Workforce:
              </h4>
              <p className="text-base md:text-lg">
                Our site managers hire and retain a first-class, experienced
                workforce committed to excellence, dedication, and integrity.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Systematic Approach:
              </h4>
              <p className="text-base md:text-lg">
                We meticulously plan each project by defining goals and
                translating them into precise contract documents, ensuring
                clarity and efficiency from start to finish.
              </p>
            </div>
          </li>
        </ol>

        <h3 className="text-2xl md:text-3xl   font-french-canon">
          Comprehensive Project Management:
        </h3>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full  text-lg font-times md:text-2xl md:text-justify">
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Quality Oversight:
              </h4>
              <p className="text-base md:text-lg">
                We provide rigorous onsite project quality management, including
                technical inspections and testing, to ensure every detail meets
                the highest standards.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                Detailed Reporting:
              </h4>
              <p className="text-base md:text-lg">
                You'll receive comprehensive project information, a construction
                closeout list, and final accounting, keeping you informed every
                step of the way.
              </p>
            </div>
          </li>
        </ol>

        <h3 className="text-2xl md:text-3xl   font-french-canon">
          Durable and Efficient Structures:
        </h3>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full  text-lg font-times md:text-2xl md:text-justify">
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <h4 className="text-2xl text-[#CCA605] font-french-canon">
                Best Practices:
              </h4>
              <p className="text-base md:text-lg">
                Our structural contracting services encompass concreting,
                brickwork, formwork, and reinforcement work, utilizing best
                practices to create structures that stand the test of time.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col">
              <h4 className="text-2xl text-[#CCA605] font-french-canon">
                Customized Solutions:
              </h4>
              <p className="text-base md:text-lg">
                We build homes tailored to your needs and personality,
                reflecting your unique style and preferences
              </p>
            </div>
          </li>
        </ol>

  
   <div className="flex flex-col xl:flex-row gap-5 md:gap-10 justify-between ">

   <div className="flex flex-col w-full xl:w-2/3 gap-5 ">
     <h3 className="text-2xl md:text-3xl font-french-canon md:text-justify">
          Your Dream Home, Built to Last:
        </h3>
      
          <p className=" text-base md:text-lg font-serif text-pretty md:text-justify">
          You can rely on Shape My House, a construction company in Noida, to create your ideal home with the highest attention to detail and accuracy. Being Delhi's top civil engineering firm, we promise a house that not only lives up to your expectations but exceeds them.
          </p>

          <p className=" text-base md:text-lg font-serif text-pretty md:text-justify">
          Discover the Shape My House distinction in Construction services in Noida where client happiness, dependability, and quality are the cornerstones of everything we do. Reach out to us right now to begin creating your ideal house!
          </p>
        </div>

        <img className="w-full xl:w-1/3" src={img1} alt="img1" /> 
   </div>
  
      </section>

      <section className="flex flex-col gap-10 md:gap-14 w-full p-5 md:p-20 md:pt-0">
        {/* <div className="flex gap-5 mx-auto">
          <PDFViewer
            className="bg-green-900  font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_Specification}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile1)}
          >
            SMH Specificatio
          </PDFViewer>
          <PDFViewer
            className="bg-green-900  font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile2)}
          >
            SMH PPT
          </PDFViewer>
          <PDFViewer
            className="bg-green-900  font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={Gurgaon_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile3)}
          >
            Gurgaon PPT
          </PDFViewer>
        </div> */}

<div className="flex flex-col md:flex-row gap-5 md:gap-10">
 
          <div className="bg-black w-full xl:w-1/2  flex flex-col justify-center items-center text-white font-glacial md:p-5">
          <img className="w-24 md:w-32 p-1 mx-auto m-3" src={logo} alt="logo" />
          <p className="text-base md:text-xl text-center p-3 font-french-canon">
            Consult with Our Expert & Start today
          </p>
          <div className="py-3">
            <a
              class="text-white text-base font-semibold hover:text-yellow-200"
              href="tel:8510850101"
            >
              <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
      
                8510 850 101
              </p>
            </a>
            <p className="text-lg text-center font-sans tracking-widest">
              Email:
              <a
                className="text-white text-base md:hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                info@tsgoc.in
              </a>
            </p>
          </div>
          <button
            onClick={handleButtonClick}
            className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e]  hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
          >
            Press
          </button>
        </div>

        <div className="flex flex-col w-full xl:w-1/2 gap-3 md:gap-5">
          <a
            href="/service/collaboration"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Collaboration
          </a>
          <a
            href="/service/renovation"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Renovation
          </a>
          <a
            href="/service/interior-design"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Interior Design
          </a>
          <a
            href="/service/architectural-design"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Architectural Design
          </a>
          <a
            href="/service/vastu-consultancy"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Vastu Consultancy
          </a>
        </div>
        </div>
       

      

      
    
      </section>
    </div>
  );
};

export default ConstructionDetails;
