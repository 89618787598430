import React from 'react'
import ArchitecturalBanner from '../component/servicepage/ArchitecturalBanner'
import ArchitecturalDetails from '../component/servicepage/ArchitecturalDetails'
import Header2 from './Header2'
import Footer from './Footer'
import { Helmet } from 'react-helmet';


const ArchitecturalDesign = () => {
  return (
    <div>
        <Helmet>
        <title>Architecture Company In Noida | Architectural Designs</title>
        <meta name="description" content="Get exceptional architecture services in Noida by our renowned architecture firm in Delhi NCR, serving with the best architectural designs." />
      </Helmet>
        <Header2/>
      <ArchitecturalBanner/>
      <ArchitecturalDetails/>
      <Footer/>
    </div>
  )
}

export default ArchitecturalDesign
