import React from "react";
import img from "../../assets/photos/blogs/SMH005.jpg";
import { Helmet } from "react-helmet";

const Blog5 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>Construction ban in Delhi reimposed as AQI Delhi worsens</title>
        <meta
          name="description"
          content="Delhi pollution and AQI Delhi NCR are the new fuss around Delhi NCR. The pollution level in Delhi has reached the severe stage and Delhi air quality got worse."
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2022-12-09 23:14:57


         </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          Construction ban reimposed in Delhi NCR as air quality worsens
          </h1>
        </div>

        <img className="w-full" src={img} alt="Construction ban in Delhi NCR" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The Commission for Air Quality Management (CAQM) again activated Stage III of the Graded Response Action Plan when the average AQI Delhi reached the "severe" stage above 400 on December 4, 2022. As a result, all unnecessary construction work has been prohibited in Delhi NCR. On November 14, 2022, the commission withdrew the necessary restrictions after improved air quality. However, a recent analysis has brought attention to the need to limit unnecessary construction and demolition in Delhi NCR.
          </p>


          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          GRAP and its stages -
          </p>
          
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        The GRAP is a set of measures followed to check the air pollution level in Delhi NCR. Generally, it is divided into four stages based on the region's average AQI.
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Stages of the Graded Response Action Plan -
          </p>

          <div className=" flex mx-auto lg:gap-36">
          <div>
            <p className="text-base md:text-2xl pb-6 px-3 font-french-canons">
            AQI
            </p>
            <ol className="flex flex-col w-fit text-base font-glacial">
              <li className="flex p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              201-300
              </li>
              <li className="flex p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              301-400
              </li>
              <li className="flex p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              401-450
              </li>
              <li className="flex p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              451 and above
              </li>
            </ol>
          </div>
          <div>
            <p className="text-base md:text-2xl pb-6 font-french-canons bg-cover">
            ZONE
            </p>
            <ol className="flex flex-col w-fit text-base font-glacial">
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Poor
              </li>
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Very poor
              </li>
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Severe
              </li>
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Severe plus
              </li>
            </ol>
          </div>

          <div>
            <p className="text-base md:text-2xl pb-6 font-french-canons">
            STAGES OF GRAP
            </p>
            <ol className="flex flex-col w-fit pl-4 lg:pl-10 text-base font-glacial">
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Stage I
              </li>
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Stage II
              </li>
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Stage III
              </li>
              <li className="flex   p-2 rounded-xl gap-5 hover:bg-red-100 hover:text-black">
              Stage IV
              </li>
              
            </ol>
          </div>
        </div>
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        The pollution reached the "severe" zone during the last week of October 2022, when the region's AQI was averaging "very poor," and it remained even after November 1, 2022. However, the limits were abolished in the third week of November 2022 since the average AQI Delhi NCR was "poor" and about 100 points below the level required to keep them in place under Stage III of GRAP. Despite the restriction being lifted, CAQM emphasized the need to follow dust control norms.
          </p>
         
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Why did Delhi NCR implement Stage III of the GRAP in October 2022?
          </p>
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        The CAQM adopted Stage III of the GRAP and imposed mandatory limits on construction and demolition activities on October 30, 2022, in response to the alarming rise in pollution levels in Delhi NCR. Since Diwali on October 24, 2022, the area had regularly shown air quality indexes above 300.
          </p>
         
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Measures were taken under Stage III of the GRAP - 
          </p>
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        Construction, demolition, and mining operations were severely restricted during Stage III in Delhi, Noida, Ghaziabad, and other NCR cities, among others. Plumbing, electrical, carpentry, and interior decorating projects were among the non-polluting activities that were permitted.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Excavation, boring, drilling, welding, loading and unloading of building supplies, drainage work, driving on an unpaved road, laying sewer and water lines, building roads, waterproofing, cutting and repairing tiles and stones, and other maintenance work were some of the operations that were prohibited. The Delhi government had assigned over 580 teams with representatives from various departments to work on the matter in order to ensure that the construction restriction wasn't broken. 
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          However, initiatives related to national security, the military, railroads, and the metro were exempt from the restriction. Additionally, because a delay in the project's completion may result in a penalty of about Rs 10 lakh per day, the prohibition was not relevant to the Noida International Airport, which is currently under development and would be located in Jewar.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          It is crucial to remember that under Stage IV of the GRAP, the restriction would have included restrictions on truck entry into Delhi NCR, only 50 percent workforce to operate out of office in public and private sectors, and temporary closure of educational institutions, to name a few.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The Noida Authority sprinkled water on various roads as per CAQM's directives, totaling almost 165 km to control the dust flying along these stretches. Additionally, the authority's horticulture division cleaned 25 km of central verges, plants, and roads. Approximately 260.75 tonnes of construction and demolition waste (C&D) were collected from various city sectors and transported to the C&D Processing Plant in Noida Sector-80 for disposal, according to the statement.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The Yamuna Expressway Industrial Development Authority (YEIDA) began investigating scientific ways of soil stabilization to control construction dust, which is a significant source of Delhi pollution, as it became more and more of an issue. According to Arun Vir Singh, CEO of YEIDA - "As the region suffers from poor AQI every winter, the authority invited organizations to give presentations on the subject. If a suitable methodology is found, the authority will look into making it a part of the construction policy."
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          How can a restriction on construction affect Delhi NCR real estate?
          </p>
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        Presently, over 5.5 lakh residential units are being constructed in Delhi NCR, including Greater Noida West, Yamuna Expressway, New Gurgaon, Noida Expressway, Dwarka Expressway, Central Noida, Greater Faridabad, Raj Nagar Extension in Ghaziabad, Sohna Road, and Golf Course Extension Road. And there are more than 4 lakh units in these areas combined. 
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          According to an industry report, a construction ban in Delhi, due to air pollution could be a serious setback for ongoing projects since residential projects in Delhi NCR typically take 6-8 years to complete. According to experts, prohibiting construction work because of poor Delhi air quality could have negative effects. If construction activity is prohibited for one month, this can delay a project by at least three to four months.
          </p>
        </div>
      </section>

      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
        <a href="/blog/know-why-home-orientation-is-necessary" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Know Why Home Orientation Is Necessary
        </a>

        <a href="/blog/the-development-and-future-of-the-construction-industry-in-india" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        The Development and future of the Construction Industry in India
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
        </a>

        <a href="/blog/key-construction-industry-trends-to-look-out-for-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Introduction of the Best Builder of Delhi - Shape My House
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog5;
