import React from "react";
import logo1 from "../assets/photos/service_page/collaboration_logo.jpg";
import logo2 from "../assets/photos/service_page/construction_logo.jpg";
const service = () => {
  return (
    <div className="flex flex-col bg-[#f0d6aa] md:flex-row gap-16 p-5 md:p-28">
      
      <section className="flex flex-col justify-center items-center gap-2 p-5 md:gap-5 w-full md:w-1/2 md:p-10 rounded-xl bg-black hover:bg-slate-900 hover:scale-105 duration-500">
        <img className="size-44 rounded-full hover:scale-125 duration-500" src={logo1} alt="logo1" />

        <h2 className="text-4xl text-[#f6c87f] md:text-5xl font-french-canon pt-5 p-3">
          Collaboration
        </h2>
        <p className="text-xl text-center text-white md:text-2xl font-serif p-3 text-pretty">
          An investor interested in property collaboration of your home or
          office space
        </p>
        <a
          className="hover:scale-110 hover:text-[#f6c87f] duration-200  font-serif "
          href="/service/collaboration"
        >
          <p className="bg-[#f6c87f] duration-700 hover:bg-black text-xl font-semibold p-2 rounded-xl">
            Read More
          </p>
        </a>
      </section>

      <section className="flex flex-col justify-center items-center gap-2 md:gap-5 p-5 w-full md:w-1/2 md:p-10 rounded-xl bg-black hover:bg-slate-900 hover:scale-105 duration-500">
        <img className="size-44 rounded-full hover:scale-125 duration-500" src={logo2} alt="logo2" />
        <h2 className="text-4xl text-[#f6c87f] md:text-5xl font-french-canon pt-5 p-3">
          Construction
        </h2>
        <p className="text-xl text-white md:text-2xl font-serif p-3 text-center text-pretty">
          A professional offering best & modern architecture plan and design
          buildings
        </p>

        <a
          className="hover:scale-110 hover:text-[#f6c87f] duration-200  font-serif "
          href="/service/construction"
        >
          <p className="bg-[#f6c87f] duration-700 hover:bg-black text-xl font-semibold p-2 rounded-xl ">
            Read More
          </p>
        </a>
      </section>
    </div>
  );
};

export default service;
