import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import blog14 from "../../assets/photos/blogs/SMH0014.jpg";
import blog13 from "../../assets/photos/blogs/SMH0013.jpg";
import blog12 from "../../assets/photos/blogs/SMH0012.jpg";
import blog11 from "../../assets/photos/blogs/SMH0011.jpg";
import blog10 from "../../assets/photos/blogs/SMH0010.jpg";
import blog9 from "../../assets/photos/blogs/SMH009.jpg";
import blog8 from "../../assets/photos/blogs/SMH008.jpg";
import blog7 from "../../assets/photos/blogs/SMH007.jpg";
import blog6 from "../../assets/photos/blogs/SMH006.jpg";
import blog5 from "../../assets/photos/blogs/SMH005.jpg";
import blog4 from "../../assets/photos/blogs/SMH004.jpg";
import blog3 from "../../assets/photos/blogs/SMH003.jpg";
import blog2 from "../../assets/photos/blogs/SMH002.jpg";
import blog1 from "../../assets/photos/blogs/SMH001.jpg";


const Blogs = () => {
  return (
    <div className="flex flex-col gap-3 md:gap-10">
      <Helmet>
        <title>
          Current blogs, updates and property insights of real estate
        </title>
        <meta
          name="description"
          content="Read these blogs and know about Indian Real Estate Market, Property sector, builders in Delhi NCR, construction services in Delhi and home construction company."
        />
      </Helmet>

      <h1 className="text-3xl md:text-5xl font-french-canon text-[#f6c87f] pt-10 text-center bg-cover">
        OUR BLOG
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-16 py-5 md:pb-16 md:m-auto">
        {/* .......14....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog14})` }}
        >
          <Link
            to="/blog/how-do-you-figure-out-the-property-valuation"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h2 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
                Admin - 2023-02-17 11:56:02
              </span>
              <br />
              How do you figure out the Property Valuation
            </h2>
          </Link>
        </div>

        {/* .......13....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog13})` }}
        >
          <Link
            to="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h2 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
                
                Admin - 2023-02-17 02:13:51
              </span>
              <br/> Introduction of the Best Builder of Delhi - Shape My House
            </h2>
          </Link>
        </div>

        {/* .......12....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog12})` }}
        >
          <Link
            to="/blog/top-5-collaboration-tools-for-real-estate"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h2 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
            
                Admin - 2023-01-28 04:28:32
              </span>
              <br /> Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
            </h2>
          </Link>
        </div>

        {/* .......11....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog11})` }}
        >
          <Link
            to="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h2 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
            
                Admin - 2023-01-21 07:03:27
              </span>
              <br /> Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
            </h2>
          </Link>
        </div>

        {/* .......10....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog10})` }}
        >
          <Link
            to="/blog/key-construction-industry-trends-to-look-out-for-in-2023"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h2 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2023-01-13 23:26:44
              </span>
              <br /> Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
            </h2>
          </Link>
        </div>

        {/* .......09....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog9})` }}
        >
          <Link
            to="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h3 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2023-01-07 02:29:53
              </span>
              <br /> Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
            </h3>
          </Link>
        </div>

        {/* .......08....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog8})` }}
        >
          <Link
            to="/blog/upcoming-smart-home-trends-&-innovations-in-2023"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h3 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2022-12-31 00:42:02
              </span>
              <br /> Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
            </h3>
          </Link>
        </div>

        {/* .......07....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog7})` }}
        >
          <Link
            to="/blog/simple-options-for-home-renovation-in-india--"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h3 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2022-12-24 03:52:04
              </span>
              <br /> Simple Options for Home Renovation in India -
            </h3>
          </Link>
        </div>

        {/* .......06....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog6})` }}
        >
          <Link
            to="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--"
            target="_blank"
            className="w-full md:h-28 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h3 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2022-12-19 08:40:11
              </span>
              <br /> Factors that make house in Gurugram the hot property at the moment -
            </h3>
          </Link>
        </div>

        {/* .......05....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog5})` }}
        >
          <Link
            to="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h3 className="text-white hover:text-black h-20">
             
              <span className="text-base font-sans font-thin">
                Admin - 2022-12-09 23:14:57
              </span>
              <br /> Construction ban reimposed in Delhi NCR as air quality worsens
            </h3>
          </Link>
        </div>

        {/* .......04....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog4})` }}
        >
          <Link
            to="/blog/where-to-purchase-residential-plots-in-gurgaon?"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h4 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2022-12-06 10:11:34
              </span>
              <br /> Where to purchase residential plots in Gurgaon?
            </h4>
          </Link>
        </div>

        {/* .......03....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog3})` }}
        >
          <Link
            to="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h4 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
                Admin - 2022-11-26 04:08:03
              </span>
              <br /> Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
            </h4>
          </Link>
        </div>

        {/* .......02....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog2})` }}
        >
          <Link
            to="/blog/the-development-and-future-of-the-construction-industry-in-india"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h4 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
               
                Admin - 2022-11-19 04:00:49
              </span>
              <br /> The Development and future of the Construction Industry in India
            </h4>
          </Link>
        </div>

        {/* .......01....... */}
        <div
          className="w-full h-56 md:h-72 md:w-96 bg-cover bg-center rounded-2xl flex justify-center items-end md:hover:scale-110 duration-500 shadow-lg shadow-zinc-600"
          style={{ backgroundImage: `url(${blog1})` }}
        >
          <Link
            to="/blog/know-why-home-orientation-is-necessary"
            target="_blank"
            className="w-full md:h-24 text-base md:text-lg font-french-canon bg-black bg-opacity-50 hover:bg-orange-100 px-5 text-center  rounded-xl p-2"
          >
            <h4 className="text-white hover:text-black h-20">
              <span className="text-base font-sans font-thin">
                Admin - 2022-11-16 10:29:38
              </span>
              <br /> Know why home orientation is necessary
            </h4>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
