import React, { useState } from 'react'
import call from "../../assets/icons/CallButton.jpg";
import enquiry from "../../assets/icons/EnquiryButton.jpg";

import whatsapp from "../../assets/icons/WhatsAppButton.jpg";
const BottomButtons = () => {
    const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div>
        <div className=" flex justify-center items-baseline gap-1 mx-3 bg-white"
        // className="left-[20px] md:left-[92vw]"
        style={{ position: "fixed", bottom: "20px",left:"5px", zIndex: 30 }}
      >
        <a
          href="tel:9870168989
"
          target="_blank"
          rel="call us bottom"
        >
          <img
           title="Call us"
            src={call}
            alt="CallUs"
            style={{
              // width: "50px",
              height: "50px",
              // borderRadius: "50%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          />
        </a>
        <div>
            {showPopup && (
        <div className="fixed top-0 left-0 right-0 bg-[rgba(0,0,0,0.5)] z-50 flex justify-center items-center h-screen">
          <div className="md:w-[40%] rounded-2xl bg-neutral-300 p-6">
            <h2 className="text-2xl md:text-3xl tracking-wide p-2 font-magnolia text-center">
            Indus Valley Mukteshwar
            </h2>
            <p className="text-sm md:text-lg px-3 text-center py-5">
            COTTAGE / APARTMENT FOR SALE IN UTTARAKHAND
          </p>
        
            <iframe
              title="Contact form"
              className="text-3xl w-full md:text-4xl h-[320px] md:h-[22vw] text-white rounded-2xl font-semibold font-serif"
              id="Contact_Form"
              src="https://account.solidperformers.com/capture_form_data/OTM3"
              border="0"
              style={{ border: 0 }}
            ></iframe>
            <button
              className="block mx-auto mt-4 bg-neutral-400 border-2 border-black hover:border-white hover:text-white rounded-xl px-4 py-2"
              onClick={togglePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
          <img className="hover:cursor-pointer"
           title="enquiry form bottom"
            src={enquiry}
            alt="Enquiry form"
            onClick={togglePopup}
            style={{
              // width: "50px",
              height: "50px",
              // borderRadius: "50%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          />
        </div>
      
     
        <a
          href="https://wa.me/919870168989
"
          target="_blank"
          rel="whats app bottom"
        >
          <img
           title="whats app"
            src={whatsapp}
            alt="WhatsApp"
            style={{
              // width: "50px",
              height: "50px",
              // borderRadius: "50%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          />
        </a>
      </div>
    </div>
  )
}

export default BottomButtons
