import React from "react";
import { Helmet } from "react-helmet";

import Project1Banner from "./Project1Banner";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";
import img1 from "../../assets/photos/ongoingProjects/ONGPRO_P2.jpg";
import img2 from "../../assets/photos/ongoingProjects/ONGPRO_P3.jpg";
import img3 from "../../assets/photos/ongoingProjects/ONGPRO_P4.jpg";
import img4 from "../../assets/photos/ongoingProjects/ONGPRO_P5.jpg";
import Header2 from "../../screens/Header2";
import Footer from "../../screens/Footer";

const Project1 = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };

  return (
    <div>
      <Header2 />
      <Helmet>
        <title>
          New construction with the duplex concept in Noida Sector 105
        </title>
        <meta
          name="description"
          content="Enjoy your life in a trendy home in 105 of Noida which was designed and allocated by the Noida Authority and has excellent transportation connections to Delhi."
        />
      </Helmet>
      <Project1Banner />
      <div className="flex flex-col md:flex-row">
        <section className="flex flex-col w-full md:w-1/3 p-5 xl:p-20 md:pt-28">
          <div className="bg-black flex flex-col justify-center items-center text-white font-glacial md:p-5">
            <img
              className="w-24 md:w-32 p-1 mx-auto m-3"
              src={logo}
              alt="logo"
            />
            <p className="text-base md:text-xl text-center p-3 font-french-canon">
              Consult with Our Expert & Start today
            </p>
            <div className="py-3">
              <a
                class="text-white text-base font-semibold hover:text-yellow-200"
                href="tel:8510850101"
              >
                <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
                  {" "}
                  8510 850 101
                </p>
              </a>
              <p className="text-lg text-center font-sans tracking-widest">
                Email:{" "}
                <a
                  className="text-white text-base md:hover:text-blue-400 "
                  href="mailto:info@tsgoc.in"
                >
                  info@tsgoc.in
                </a>
              </p>
            </div>
            <button
              onClick={handleButtonClick}
              className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e]  hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
            >
              Press
            </button>
          </div>
        </section>
        <section className="flex flex-col gap-3 md:gap-5 w-full md:w-2/3 p-5 md:p-10">
          <h3 className="text-3xl md:text-4xl font-french-canon tracking-wider">
            Project Detail :-
          </h3>

          <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
            Enjoy your life in a graceful and stylish home located in a hub of
            Noida’s (New Okhla Industrial Development Authority) high-class
            sector 105, which is planned, integrated and allotted by Noida
            Authority having modern Industrial strategies, well connected to
            Delhi through a network of roads, national highways and the
            ultra-modern DND flyover, offering inter - road linkages to all
            parts of the country.
          </p>
          <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
            A Building is situated in a highlighted Location of Noida Sector 105
            with a plot area of 200sq yards comprising of Stilt Parking, Ground
            floor, First Floor, Second Floor and Terrace. The stilt floor is
            having Gym, Electrical room and Servant room with a Toilet. Property
            has big size Modular Kitchen, Drawing room, Living room, Party Hall,
            Four large size bedrooms with balconies, Five bathrooms, and a lift
            (all as per Vastu compliance)
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 m-auto gap-7">
            <img src={img1} alt="prop img1" />
            <img src={img2} alt="prop img2" />
            <img src={img3} alt="prop img3" />
            <img src={img4} alt="prop img4" />
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Project1;
