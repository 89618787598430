import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pic1 from "../assets/photos/about_page/about_pic1.webp";
import pic2 from "../assets/photos/about_page/about_pic2.webp";
import pic3 from "../assets/photos/about_page/about_pic3.webp";
import pic4 from "../assets/photos/about_page/about_pic4.webp";
import pic5 from "../assets/photos/about_page/about_pic5.webp";


// Avatar,

function AboutCarousal() {
  var settings = {
    autoplay: true,
    
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {/* <Head>
        <title>About Us - Elite Building Solutions</title>
        <meta
          name="description"
          content="Elite Building Solutions provides quality construction solutions including architecture, construction, collaboration, and renovation services."
        />
      </Head> */}

      <div id="2" className="p-5 md:p-20 md:pt-10 flex flex-col gap-5 bg-white">
        <div className="flex flex-col gap-5">
          <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
            COMPANY OVERVIEW
          </h2>
          <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
            ELITE BUILDING SOLUTIONS
          </h3>
          <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
            A PERFECT DESTINATION FOR ALL YOUR CONSTRUCTION SOLUTIONS IN ONE GO.
            WE PROVIDE SERVICES LIKE CONSTRUCTION & COLLABORATION.
          </p>
        </div>
        <Slider {...settings}>
          {/* Section1............ */}
          <section className="flex flex-col md:flex-row justify-center items-center border-2 md:p-8 md:py-14  bg-[#eecf9d] border-[#f2bf6d]">
            <div className="flex flex-col xl:flex-row gap-5 md:justify-center md:items-center md:shadow-xl md:shadow-black bg-[#fbecd3] hover:bg-white md:rounded-xl ">
              <img
                className="w-full  md:rounded-l-xl"
                src={pic1}
                alt="img1"
                size="sm"
              />
              <div className="flex flex-col gap-5 p-5 ">
                <h3 className=" text-2xl md:text-3xl  font-french-canon">
                  OUR GOAL THEN AND NOW IS TO PROVIDE QUALITY PROJECTS ON TIME
                </h3>
                <p className="text-pretty md:text-justify">
                  Shubham Group delivers group housing projects in the various
                  locations of India like Ambika Divinity Haridwar, Indus Valley
                  Mukteshwar, Indus Valley Shimla, Cloud Valley Dhanaulti, and
                  Ocean Valley Goa. Shape My House provides services like
                  Architecture, Construction, Collaboration, and Renovation.
                </p>
              </div>
            </div>
          </section>

          {/* Section2............ */}
          <section className="flex flex-col xl:flex-row justify-center items-center border-2 md:p-8 md:py-14 bg-[#eecf9d] border-[#f2bf6d]">
            <div className="flex flex-col xl:flex-row gap-5 md:justify-center md:items-center md:shadow-xl md:shadow-black bg-[#fbecd3] hover:bg-white md:rounded-xl ">
              <img
                className="w-full md:rounded-l-xl"
                src={pic2}
                alt="img2"
                size="sm"
              />
              <div className="flex flex-col gap-5 p-5">
                <h3 className=" text-2xl md:text-3xl font-french-canon">
                  WE DELIVER PREMIUM QUALITY AND EFFICIENT CIVIL PROJECTS
                </h3>
                <p className="text-pretty md:text-justify">
                  Shape My House's sole objective is to transparently work for
                  those who need any Architectural design, House/Office
                  Construction, Collaboration, and Renovation job. We Serve them
                  with ethical and experienced Building Contractor in Delhi NCR
                  & interior designing services. The role of Shape My House is
                  to understand the requirement of the client and suggest or
                  provide them with the best quality products and services on
                  specific deadline.
                </p>
              </div>
            </div>
          </section>

          {/* Section3........ */}
          <section className="flex flex-col xl:flex-row justify-center items-center border-2 md:p-8 md:py-14 bg-[#eecf9d] border-[#f2bf6d]">
            <div className="flex flex-col xl:flex-row gap-5 md:justify-center md:items-center md:shadow-xl md:shadow-black bg-[#fbecd3] hover:bg-white md:rounded-xl">
              <img
                className="w-full md:rounded-l-xl
            "
                src={pic3}
                alt="img3"
                size="sm"
              />
              <div className="flex flex-col gap-5 p-5">
                <h3 className=" text-2xl md:text-3xl font-french-canon md:tracking-wide">
                  WE PROVIDE FIRST CLASS WORKFORCE BY APPROACHING THE BUILDING
                  PROCESS WITH DEDICATION
                </h3>
                <p className="text-pretty md:text-justify">
                  Shubham Group envisions the primary preference nationally and
                  globally, for their renowned excellence, quality, performance
                  and reliability in all types of constructions.
                </p>
              </div>
            </div>
          </section>

          {/* Section4........ */}
          <section className="flex flex-col md:flex-row justify-center items-center border-2 md:p-8 md:py-14 bg-[#eecf9d] border-[#f2bf6d]">
            <div className="flex flex-col xl:flex-row gap-5 md:justify-center md:items-center md:shadow-xl md:shadow-black bg-[#fbecd3] hover:bg-white md:rounded-xl">
              <img
                className="w-full md:rounded-l-xl
            "
                src={pic4}
                alt="img4"
                size="sm"
              />
              <div className="flex flex-col gap-5 p-5">
                <h3 className=" text-2xl md:text-3xl font-french-canon">
                  PRESENTING ONLY THE BEST TO OUR CLIENTS
                </h3>
                <p className="text-pretty md:text-justify">
                  We are the pre-eminent provider of superior construction
                  services by consistently improving the quality of our product;
                  to add value for clients through innovation, foresight,
                  integrity and performance.
                </p>
              </div>
            </div>
          </section>

          {/* Section5........ */}
          <section className="flex flex-col xl:flex-row justify-center items-center border-2 md:p-8 md:py-14 bg-[#eecf9d] border-[#f2bf6d]">
            <div className="flex flex-col xl:flex-row gap-5 md:justify-center md:items-center md:shadow-xl md:shadow-black bg-[#fbecd3] hover:bg-white md:rounded-xl">
              <img
                className="w-full md:rounded-l-xl
            "
                src={pic5}
                alt="img5"
                size="sm"
              />
              <div className="flex flex-col gap-5 p-5">
                <h3 className=" text-2xl md:text-3xl font-french-canon md:tracking-wide">
                  OUR ENDEAVOUR IS TO DELIVER REALITY SPACES IN THE DYNAMICALLY
                  GROWING AREAS
                </h3>
                <p className="text-pretty md:text-justify">
                  During renovation projects, flexibility is often required from
                  renovation companies to respond to unexpected issues that
                  arise. A plan that had been agreed upon by multiple parties is
                  followed. The planning process will involve feedback from
                  financial investors of the project and from the designer.
                </p>
              </div>
            </div>
          </section>
        </Slider>
       
      </div>
    </>
  );
}

export default AboutCarousal;
