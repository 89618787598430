import React from "react";
import { Helmet } from "react-helmet";
import Header2 from '../../screens/Header2'
import Footer from '../../screens/Footer'

import Project1Banner from "./Project1Banner";
import logo from "../../assets/photos/ongoingProjects/customer-support.png"
import img1 from "../../assets/photos/ongoingProjects/ONGPRO3.jpg"
import img2 from "../../assets/photos/ongoingProjects/ONGPRO5.jpg"

const Project2 = () => {
    const handleButtonClick = () => {
        window.open("/contact_us", "_blank");
      };

  return (
    <div>
      <Header2/>
       <Helmet>
        <title>
        Best 4 BHK Apartment In DLF Phase 3 Gurgaon | ShapeMyHouse
        </title>
        <meta
          name="description"
          content=" Explore Luxurious 4 Bhk Flats in Dlf Phase 3, Gurgaon, Offering Spacious Living and Modern Amenities. Find Your Dream 4 Bhk in Gurgaon Here."
        />
      </Helmet>
      <Project1Banner />
      <div className="flex flex-col md:flex-row">
        <section className="flex flex-col w-full md:w-1/3 p-5 xl:p-20 md:pt-28">
          <div className="bg-black flex flex-col justify-center items-center text-white font-glacial md:p-5">
          <img className="w-24 md:w-32 p-1 mx-auto m-3" src={logo} alt="logo" />
            <p className="text-base md:text-xl text-center p-3 font-french-canon">
              Consult with Our Expert & Start today
            </p>
            <div className="py-3">
                  <a
                class="text-white text-base font-semibold hover:text-yellow-200"
                href="tel:8510850101"
              >
                 <p className="text-lg md:text-2xl text-center font-sans tracking-widest"> 8510 850 101</p>
              </a>
              <p className="text-lg text-center font-sans tracking-widest">Email:  <a
                className="text-white text-base md:hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                info@tsgoc.in
              </a></p>
            </div>
            <button
        onClick={handleButtonClick}
        className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e]  hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none">
        Press
      </button>
          </div>
        </section>
        <section className="flex flex-col gap-3 md:gap-5 w-full md:w-2/3 p-5 md:p-10">
           <h1 className="text-3xl md:text-4xl font-french-canon tracking-wider">
           Explore Luxurious <span className="font-sans">4</span> Bhk Apartment in Dlf Phase <span className="font-sans">3</span>, Gurgaon
        </h1>

        <h2 className="text-xl md:text-2xl font-french-canon tracking-wider">
        Looking for spacious and luxurious flats for sale in Gurgaon? Look no further!
        </h2>
        <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
        At Shape My House, we provide exquisite apartments in the sought-after DLF Phase 3 area of Gurgaon, offering the epitome of contemporary living.
          </p>
          <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
          Gurgaon, recognized for its fast urbanization and robust infrastructure, has emerged as a prime destination for real estate investments.
          </p>
          <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
          If you are looking for a home that combines sophistication, comfort, and convenience, a flat in Gurgaon is an exceptional choice. These spacious apartments provide ample room for your family's needs, while ensuring a high standard of living. DLF Phase 3 is a prestigious locality known for its upscale residential offerings. It's the perfect place for those seeking tranquility amidst the bustling city life. The area boasts excellent connectivity, proximity to commercial hubs, and top-notch amenities, making it an ideal choice for families and professionals alike. Our services feature a curated selection of flats for sale in Gurgaon, with a particular focus on DLF Phase 3. These apartments are thoughtfully designed to cater to your modern lifestyle needs. From spacious bedrooms and elegant living spaces to state-of-the-art kitchens, every detail is crafted to perfection.
          </p>
          <h2 className="text-xl md:text-2xl font-french-canon tracking-wider">
          Why Choose Shape My House?
        </h2>
          <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
          Shape My House provides a user-friendly platform to simplify your search. Our extensive database of properties allows for easy filtering and finding of the ideal flat that meets both your preferences and budget. Our team of experienced real estate experts is dedicated to guiding you through every step of the buying process.
          </p>
          <p className="text-base md:text-lg font-glacial text-pretty md:text-justify">
          Whether you're a first-time homebuyer or a seasoned investor, we provide personalized guidance to ensure a seamless transaction.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 m-auto gap-7">
            <img src={img1} alt="prop img1"/>
            <img src={img2} alt="prop img2"/>
           </div>
        </section>
      </div>
      <Footer/>
    </div>
  );
};

export default Project2;
